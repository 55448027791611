<template>
  <section class="section">
    <div class="container">
      <h1 class="title">{{ $t("features.welcome") }}</h1>
      <b-field>
        <b-switch v-model="isUserCreateEventsEnabled">
          {{ $t("features.userCreateEvents") }}
        </b-switch>
      </b-field>
    </div>
  </section>
</template>

<script>
import { FeatureFlag } from "@/constants";

export default {
  name: "ExperimentsView",
  metaInfo: {
    title: "Follow the white rabbit.",
  },
  computed: {
    isUserCreateEventsEnabled: {
      get() {
        return this.$cookies.isKey(FeatureFlag.UserCreateEvents);
      },
      set(value) {
        if (value) {
          this.$cookies.set(FeatureFlag.UserCreateEvents, "1", "365d");
        } else {
          this.$cookies.set(FeatureFlag.UserCreateEvents, "1", -2);
        }

        this.$router.go(0);
      },
    },
  },
};
</script>
